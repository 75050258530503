.stats {
  width: 400px;
  height: 400px;
  background: rgba(26, 53, 66, 0.787);
  position: absolute;
  bottom: 100px;
  border-radius: 25px;
}

.stats-competencies {
  width: 400px;
  height: 125px;
  background: rgba(26, 53, 66, 0.787);
  position: absolute;
  bottom: 525px;
  border-radius: 25px;
}

.stats-icon {
  height: 50px;
  width: 50px;
  position: absolute;
}

.stats-icon-first {
  height: 50px;
  width: 50px;
  position: absolute;
  animation-name: col1;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes col1 {
  0% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 4000px;
  }

  15% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 1550px;
  }
}

.stats-icon-second {
  height: 50px;
  width: 50px;
  position: absolute;
  animation-name: col2;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes col2 {
  0% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 4000px;
  }

  15% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 1630px;
  }
}

.stats-icon-third {
  height: 50px;
  width: 50px;
  position: absolute;
  animation-name: col3;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes col3 {
  0% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 4000px;
  }

  15% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 1710px;
  }
}

.stats-icon-fourth {
  height: 50px;
  width: 50px;
  position: absolute;
  animation-name: col4;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes col4 {
  0% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 4000px;
  }

  15% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    left: 1790px;
  }
}

.arrow {
  animation: arrow 1s infinite;
}

.arrow1 {
  animation: arrow1 1s infinite;
}

@keyframes arrow {
  0% {
    transform: translateX(0px) scaleX(1) scaleY(1) rotate(25deg);
  }
  66% {
    transform: translateX(20px) scaleX(1) scaleY(1) rotate(25deg);
  }
  100% {
    transform: translateX(0px) scaleX(1) scaleY(1) rotate(25deg);
  }
}

@keyframes arrow1 {
  0% {
    transform: translateX(20px) scaleX(-1) scaleY(1) rotate(25deg);
  }
  66% {
    transform: translateX(0px) scaleX(-1) scaleY(1) rotate(25deg);
  }
  100% {
    transform: translateX(20px) scaleX(-1) scaleY(1) rotate(25deg);
  }
}
