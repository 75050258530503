@font-face {
  font-family: LapsusPro-Bold;
  src: url("./LapsusPro-Bold.otf") format("opentype");
}

html {
  /* overflow: hidden; */
  background: rgb(0, 65, 114);
  /* scroll-behavior: smooth; */
  font-family: "LapsusPro-Bold";
  color: white;
  overflow: hidden;
}

body {
  overflow: hidden;
  position: relative;
}

.darkener {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/darkenerlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.3;
}

.bgcolor {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/backgroundColorlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.frontshadow {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/frontshadowlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.leftbackmountain {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/leftbackmountainlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.leftmiddlerockpick {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/leftmiddlerockpicklong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.leftmountain {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/leftmountainlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.leftmountainfloorpick {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/leftmountainfloorpicklong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.leftmountainmiddle {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/leftmountainmiddlelong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.moon {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/moonlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.otherastro {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/otherastrolong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.3;
}

.rightbackmountain {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/rightbackmountainlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rightmiddlerockpick {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/rightmiddlerockpicklong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rightmountain {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/rightmountainlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rockfloor {
  width: 12000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/rockfloorlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.shineline {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/shinelinelong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

.smokebackground {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/smokebackgroundlong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.3;
}

.stars {
  width: 8000px;
  max-height: 785px;
  height: 100vh;
  background-image: url("./assets/cave/starslong.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: absolute;
  bottom: 0;
  left: 0;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;

}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }