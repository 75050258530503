.aws-info {
  width: 400px;
  height: 200px;
  background: rgba(26, 53, 66, 0.787);
  position: absolute;
  bottom: 200px;
  border-radius: 25px;
}

.aws-icon {
  height: 50px;
  width: 50px;
  position: absolute;
}

.cloud-stats-icon {
  height: 50px;
  width: 50px;
  position: absolute;
  animation-name: bounce;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.cloud-stats-icon:nth-child(2n) {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.cloud-stats-icon:nth-child(3n) {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

@keyframes bounce {
  8% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    bottom: 500px;
    height: 50px;
    width: 50px;
  }
  17% {
    bottom: 250px;
    height: 50px;
    width: 50px;
  }

  26% {
    bottom: 500px;
    height: 50px;
    width: 50px;
  }
  35% {
    bottom: 300px;
    height: 50px;
    width: 50px;
  }
  44% {
    bottom: 500px;
    height: 50px;
    width: 50px;
  }

  53% {
    bottom: 350px;
    height: 50px;
    width: 50px;
  }
  62% {
    bottom: 500px;
    height: 50px;
    width: 50px;
  }
  71% {
    bottom: 375px;
    height: 50px;
    width: 50px;
  }
  80% {
    bottom: 500px;
  }

  100% {
    bottom: 400px;
  }
}

.clouds {
    width: 1330px;
    height: 700px;
    background: none;
    position: absolute;
    bottom: 98px;
    transform: rotate(1deg);
  }
  