form {
  /* margin: 5rem auto 0; */
  width: 24rem;
}

.divselect {
  margin-bottom: 1.5rem;
  width: 24rem;
}

.inputselect {
  padding: 0 0.5rem;
  width: 24rem;
  height: 3.5rem;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
}

.inputareaselect {
  padding: 0 0.5rem;
  width: 24rem;
  height: 3.5rem;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
}

.pstuff {
  margin-top: 0.5rem;
  color: #57d5d6;
}

.count {
  padding: 0.25rem 0;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 40px;
}

.noscrollbars {
  padding: 0.5rem 0.5rem;
  max-width: 380px;
  max-height: 100px;
  min-width: 380px;
  min-height: 100px;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
}
