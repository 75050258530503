.spin {
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes spin {
  0% {
    bottom: 550px;
  }
  50% {
    bottom: 520px;
  }
  100% {
    bottom: 550px;
  }
}
