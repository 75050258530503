@import url("https://fonts.googleapis.com/css?family=Poppins:900i");

.hamburger-wrapper {
  height: 785px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
  transform: width;
  transition: all 500ms ease-out;
}

.ham-button-wrapper {
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 725px;
  left: 20px;
  z-index: 5;
}

.list-wrapper {
  height: 785px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.8);
  transform: width;
  transition: all 500ms ease-out;
  overflow: hidden;
  z-index: 4;
  overflow-y: scroll;
}

.cta {
  display: flex;
  align-items: center;
  padding: 10px 45px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: white;
  background: #6225e6;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  margin-bottom: 12px;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #57d5d6;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

span {
  transform: skewX(15deg);
}

span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: 12%;
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
  0% {
    fill: white;
  }
  50% {
    fill: #57d5d6;
  }
  100% {
    fill: white;
  }
}
