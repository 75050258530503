
.speech-bubble {
  width: 400px;
  height: 200px;
  margin: 0.5em auto;
  padding: 1em;
  border-radius: 25px;
  transform: rotate(-4deg) rotateY(15deg);
  background: #629bdd;
  text-align: center;
  position: absolute;
  animation-name: speech;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.sppechvisual {
  width: 400px;
  height: 200px;
  margin: 0.5em auto;
  padding: 1em;
  border-radius: 25px;
  transform: rotate(-4deg) rotateY(15deg);
  background: white;
  text-align: center;
  position: absolute;
  color: black;
  animation-name: speech;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.triangle {
  width: 0;
  height: 0;
  border-bottom: 88px solid #629bdd;
  border-left: 2px solid transparent;
  border-right: 56px solid transparent;
  position: absolute;
  animation-name: triangle2;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.triangle2 {
  width: 0;
  height: 0;
  border-bottom: 88px solid white;
  border-left: 2px solid transparent;
  border-right: 56px solid transparent;
  position: absolute;
  animation-name: triangle;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}


@keyframes speech {
  0% {

    width: 0px;
    height: 0px;
  }
  8% {

    width: 500px;
    height: 300px;
  }
  15% {

    width: 400px;
    height: 200px;
  }
}