.trees-sprout {
  height: 1084px;
  width: 1800px;
  bottom: -300px;
  background: none;
  position: absolute;
  animation-name: grow;
  animation-duration: 6s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.trees-unsprout {
  height: 0px;
  width: 1800px;
  bottom: -300px;
  background: none;
  position: absolute;
  animation-name: ungrow;
  animation-duration: 6s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes grow {
  0% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    height: 0px;
  }
  12% {
    height: 1100px;
  }
  17% {
    height: 1000px;
  }
  22% {
    height: 1085px;
  }
}

@keyframes ungrow {
  0% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    height: 1085px;
  }
  12% {
    height: 1000px;
  }
  17% {
    height: 1100px;
  }
  22% {
    height: 0px;
  }
}

.portland-wrapper {
  position: absolute;
  bottom: 450px;
  height: 200px;
  width: 800px;
  animation-name: portland;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.cloudsrain {
  width: 2000px;
  height: 700px;
  background: none;
  position: absolute;
  bottom: 140px;
}

@keyframes portland {
  0% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    bottom: -100px;
  }
  25% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    bottom: 500px;
  }
  45% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    bottom: 350px;
  }
  65% {
    /* Use the first 8% of duration time to 
      reach bottom which, will be a quick fall */
    bottom: 450px;
  }
  /* 100% {
      bottom: 500px;
    } */
}
