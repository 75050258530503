.firebase {
  height: 240px;
  width: 140px;
  background: none;
  position: absolute;
  bottom: 10px;
  left: 68%;
  animation-name: firebase;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes firebase {
  0% {
    height: 240px;
    width: 140px;
  }
  25% {
    height: 220px;
    width: 150px;
  }
  50% {
    height: 240px;
    width: 140px;
  }
  75% {
    height: 220px;
    width: 150px;
  }
  100% {
    height: 240px;
    width: 140px;
  }
}

.firebase-icons {
  height: 50px;
  width: 50px;
  background: none;
  position: absolute;
  bottom: 100px;
  left: 72%;
  animation-name: firebaseicons;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.firebase-icons:nth-child(2n) {
  left: 69%;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.firebase-icons:nth-child(2n) {
  left: 71%;
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
.firebase-icons:nth-child(3n) {
  left: 69%;
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}
.firebase-icons:nth-child(4n) {
  left: 71%;
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}
.firebase-icons:nth-child(5n) {
  left: 69%;
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
}
.firebase-icons:nth-child(6n) {
  left: 71%;
  animation-delay: 1.8s;
  -webkit-animation-delay: 1.8s;
}
.firebase-icons:nth-child(7n) {
  left: 69%;
  animation-delay: 2.1s;
  -webkit-animation-delay: 2.1s;
}
v .firebase-icons:nth-child(8n) {
  left: 71%;
  animation-delay: 2.3s;
  -webkit-animation-delay: 2.3s;
}

@keyframes firebaseicons {
  0% {
    bottom: 100px;
    opacity: 100%;
  }
  80%{
    opacity: 70%;
  }
  100% {
    bottom: 500px;
    opacity: 20%;
  }
}

.firebase-info {
  width: 400px;
  height: 200px;
  background: rgba(26, 53, 66, 0.787);
  position: absolute;
  bottom: 500px;
  border-radius: 25px;
}
